export const preventDefault = (func=_.noop, ...args) => (e) => {
  e.preventDefault();
  return func(...args, e);
};

export const stopPropagation = (func=_.noop, ...args) => (e) => {
  e.stopPropagation();
  return func(...args, e);
};

export const stopAll = (func=_.noop, ...args) => (e) => {
  e.preventDefault();
  e.stopPropagation();
  return func(...args, e);
};
