import { createContext, useState, useEffect } from 'react';
import { refreshHeader } from 'shared/api_request_helpers';
import SignInModal from './components/SignInModal';

const SessionContext = createContext();

export function SessionProvider({children, currentUser}) {
  const [user, setUser] = useState(currentUser);
  const [show, setShow] = useState(false);
  const [resetKey, setResetKey] = useState(0);

  useEffect(() => {
    if (show) setResetKey(resetKey + 1);
  }, [show]);

  const contextAPI = {
    user,
    userID: user?.id,
    userType: user?.user_type,
    adopter: user?.user_type === "Adopter" ? user : null,
    modalActive: !!show,
    showSignInModal({onSuccess, ...options}={}) {
      options.onSuccess = async (adopter) => {
        setUser(adopter);
        await refreshHeader();
        onSuccess?.(adopter);
        setShow(false);
      };
      setShow(options);
    },
    updateUser(patch={}) {
      setUser((user) => user ? {...user, ...patch} : user);
    }
  };

  return (
    <SessionContext.Provider value={contextAPI}>
      {children}
      <SignInModal key={resetKey} show={show} dismiss={() => setShow(false)} />
    </SessionContext.Provider>
  );
}

export default SessionContext;
