import { useState } from 'react';
import {
  Modal, ModalHeader, ModalBody, ModalFooter, Button, FormGroup, Label, Input, Alert
 } from 'reactstrap';
import { preventDefault } from 'shared/events';
import { errorsToSentence } from 'components/shared/humanize';
import { signInAdopter, signUpAdopter } from 'shared/api_request_helpers';

export default function SignInModal({show, dismiss}) {
  const [signUpMode, setSignUpMode] = useState(false);
  const [error, setError] = useState("");

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [subscribe, setSubscribe] = useState(false);

  const toggleMode = () => setSignUpMode(!signUpMode);

  const signIn = async () => {
    setError("");
    try {
      const SignInUp = signUpMode ? signUpAdopter : signInAdopter;
      const adopter = await SignInUp({email, password, passwordConfirm, subscribe});
      show.onSuccess?.(adopter)
    } catch (e) {
      setError(errorsToSentence(e.response) || _.get(e.response, 'data.error', e.message));
    }
  };

  return (
    <Modal isOpen={!!show} toggle={dismiss} keyboard>
      <form onSubmit={preventDefault(signIn)}>
        <ModalHeader>Sign in or sign up</ModalHeader>
        <ModalBody>
          <p className="text-muted">
            Create an account and streamline the process of finding your #RightHorse by saving
            searches, building a barn of favorited horses and saving your information so you can
            easily inquire on multiple equines!
          </p>
          {error && <Alert color="danger">{error}</Alert>}
          <FormGroup>
            <Label for="email">Email Address</Label>
            <Input type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              autoComplete="username"/>
          </FormGroup>
          <FormGroup>
            <Label for="password">
              Password
              {!signUpMode && (
                <a className="sign-in-forgot pl-1" tabIndex="-1" href="/adopters/password/new">
                  Forgot?
                </a>
              )}
            </Label>
            <Input type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              autoComplete={signUpMode ? "new-password" : "current-password"}/>
          </FormGroup>
          {signUpMode && <>
            <FormGroup>
              <Label for="passwordConfirm">Confirm Your Password</Label>
              <Input type="password"
                id="passwordConfirm"
                value={passwordConfirm}
                onChange={(e) => setPasswordConfirm(e.target.value)}
                autoComplete="new-password"/>
            </FormGroup>
            <FormGroup check inline className="mb-3">
              <Input type="checkbox"
                id="adopter_subscribe"
                checked={subscribe}
                onChange={(e) => setSubscribe(e.target.checked)}/>
              <Label check for="adopter_subscribe">
                I would like to hear from ASPCA Right Horse program
              </Label>
            </FormGroup>
          </>}
          {signUpMode ? "Already" : "Don't"} have an account?{" "}
          <Button onClick={toggleMode} color="link">Sign {signUpMode ? "in" : "up"} now!</Button>
        </ModalBody>
        <ModalFooter>
          <Button onClick={dismiss}>Cancel</Button>
          <Button type="submit" color="primary">Sign {signUpMode ? "Up" : "In"}</Button>
        </ModalFooter>
      </form>
    </Modal>
  );
}
