import Search from './Search/containers/Search';
import SessionContext, { SessionProvider } from '@/contexts/SessionContext';

export default function SearchApp({user, ...props}) {
  return (
    <SessionProvider currentUser={user}>
      <SessionContext.Consumer>
        {(sessionProps) => <Search {...props} {...sessionProps}/>}
      </SessionContext.Consumer>
    </SessionProvider>
  );
}
