import { useState } from 'react';
import flash from 'shared/flash';
import { stopAll } from 'shared/events';
import { updateSearchNotification, unsubscribeSearch } from 'shared/api_request_helpers';

const TOOLTIP = {"data-toggle": "tooltip", "data-placement": "top"};

export default function SavedSearchTitle({subscription}) {
  const [editMode, setEditMode] = useState(false);
  const [name, setName] = useState(subscription.name || "");

  const toggleEdit = () => {
    setEditMode(!editMode);
    $('[data-toggle="tooltip"]').tooltip('dispose').tooltip();
  };
  const saveName = async () => {
    try {
      await updateSearchNotification(subscription.id, {name});
      flash('success', "Successfully renamed search notification");
    } catch (e) {
      flash('error', _.get(e.response, 'data.error', e.message));
    }
  };
  const deleteSavedSearch = async () => {
    try {
      await unsubscribeSearch(subscription.id);
      flash('success', "Successfully unsubscribed from search notification");
      window.location.reload();
    } catch (e) {
      flash('error', _.get(e.response, 'data.error', e.message));
    }
  };
  const keyboardShortcuts = ({key}) => {
    switch (key) {
    case "Escape":
      setName(subscription.name);
      return;
    case 'Enter':
      setEditMode(false);
      saveName();
    }
  };

  return (
    <h5 className="card-title">
      <i className="icon-search primary mr-1"/>
      {editMode ? (
        <input name="search_name"
          autoFocus
          value={name}
          onChange={(e) => setName(e.target.value)}
          onClick={stopAll()}
          onBlur={saveName}
          onKeyUp={keyboardShortcuts}
          className="name"/>
      ) : (
        <span className="name">{name || "Search Filters"}</span>
      )}
    <button onClick={stopAll(toggleEdit)} className="btn btn-light">
      {editMode ? (
        <i className="icon-saveandcircle" title="Save" aria-label="Save" {...TOOLTIP}/>
      ) : (
        <i className="icon-editandcircle" title="Rename" aria-label="Rename" {...TOOLTIP}/>
      )}
    </button>
    <button onClick={stopAll(deleteSavedSearch)} className="btn btn-light text-danger">
      <i className="icon-trashandcircle" title="Unsubscribe" aria-label="Unsubscribe" {...TOOLTIP}/>
    </button>
    </h5>
  );
}
