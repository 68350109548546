import BooleanSelect from "./BooleanSelect";
import MultiSelect, { listToSelectOptions } from "./MultiSelect";

const CRITERIA_OPTIONS = [
  'Former Broodmare',
  'Cribber',
  'Requires Rehab',
  'Has Career Limitations',
];

const NOT_ELIGIBLE_MESSAGE = `
  Your horse does not appear to be eligible for the 2025
  Retired Racehorse Project’s Thoroughbred Makeover. You can still list this horse on
  myrighthorse.org, but they will not receive a scholarship listing or be cross promoted on
  the Retired Racehorse Project’s website. If you believe you’ve received this message in
  error, please contact therighthorse@aspca.org
`;

const eligible = (rrp) => {
  if (!rrp) return;
  return (rrp.racehorse && rrp.last_race_date > '2023-07-01') || (rrp.broodmare && rrp.last_cover_date > '2023-01-01');
};
const answered = (rrp) => {
  if (!rrp) return;
  return Boolean((rrp.racehorse && rrp.last_race_date) || (rrp.broodmare && rrp.last_cover_date));
};
const promotable = (rrp) => {
  if (!rrp) return;
  return Boolean(eligible(rrp) && rrp.criteria?.length && rrp.ready);
};
const withDerived = (rrp) => ({...rrp, eligible: eligible(rrp), promotable: promotable(rrp)});

export default function RRPQuestionare({value={}, updateField}) {
  const update = (key, newValue) => updateField('rrp', withDerived({...value, [key]: newValue}));
  
  return (
    <div className="rrp-section">
      <div className="form-group">
        <h4>2025 Thoroughbred Makeover-Eligible</h4>

        <div className="multi-select-options">
          <div className="form-control border-0">
            <div className="custom-control custom-checkbox">
              <input
                type="checkbox"
                className="custom-control-input"
                id="rrp_racehorse"
                checked={_.get(value, 'racehorse', false)}
                onChange={(e) => update('racehorse', e.target.checked)}
              />
              <label className="custom-control-label" htmlFor="rrp_racehorse">
                Retiring Racehorse
              </label>
            </div>
          </div>
          <div className="form-control border-0">
            <div className="custom-control custom-checkbox">
              <input
                type="checkbox"
                className="custom-control-input"
                id="rrp_broodmare"
                checked={_.get(value, 'broodmare', false)}
                onChange={(e) => update('broodmare', e.target.checked)}
              />
              <label className="custom-control-label" htmlFor="rrp_broodmare">
                Former Broodmare
              </label>
            </div>
          </div>
        </div>
      </div>
      
      {value?.racehorse && (
        <div className="form-group">
          <label htmlFor="rrp_last_race_date">Date of last race or work</label>
          <input type="date"
            id="rrp_last_race_date"
            name="last_race_date"
            className="form-control"
            placeholder="yyyy-mm-dd"
            value={value.last_race_date || ""}
            onChange={(e) => update('last_race_date', e.target.value)}/>
        </div>
      )}
      {value?.broodmare && (
        <div className="form-group">
          <label htmlFor="rrp_last_cover_date">Date of last cover</label>
          <input type="date"
            id="rrp_last_cover_date"
            name="last_cover_date"
            className="form-control"
            placeholder="yyyy-mm-dd"
            value={value.last_cover_date || ""}
            onChange={(e) => update('last_cover_date', e.target.value)}/>
        </div>
      )}
      {answered(value) && (eligible(value) ? <>
        <MultiSelect
          fieldName="criteria"
          label="Does this horse meet any of the following criteria?"
          required={false}
          values={value?.criteria || []}
          options={listToSelectOptions(CRITERIA_OPTIONS)}
          updateField={(newValue) => update('criteria', newValue)}
        />
        <BooleanSelect
          complete={value?.ready}
          label="Is it reasonable to assume this horse will be sound W/T/C under saddle by August of 2025 and would be able to comfortably train for the TB Makeover?"
          fieldName="ready"
          updateField={update}
          trueLabel="Yes"
          falseLabel="No"/>
      </> : (
        <div className="alert alert-warning">
          {NOT_ELIGIBLE_MESSAGE}
        </div>
      ))}
      {promotable(value) && (
        <div className="alert alert-success">
          Your horse will be promoted through Retired Racehorse Project’s Thoroughbred Makeover!
        </div>
      )}
    </div>
  );
}
  